<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>运营管理</a-breadcrumb-item>
    <a-breadcrumb-item>企微列表</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 弹簧 -->
    <div style="flex: 1;"></div>
    <!-- 操作 -->
    <a-button type="primary" @click="touchAdd"><plus-outlined />添加</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <div style="white-space: nowrap;">
          <a class="operation-item" @click="touchEdit(record)">编辑</a>
          <a class="operation-item" @click="touchDelete(record)">删除</a>
        </div>
      </template>
    </template>
  </a-table>
  <!-- 新增 -->
  <Add ref="RefAdd" @success="getList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message, Modal } from 'ant-design-vue'
import { wechatAccountList, wechatAccountDelete } from '@/api/copyright'
import Add from './components-wecom/AddDrawer.vue'

// 每日数据
let RefAdd = ref(null)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '企微名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '企微账号',
    dataIndex: 'account',
    key: 'account'
  },
  {
    title: '对应微小',
    dataIndex: 'project_name',
    key: 'project_name'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 初始化选择项列表
  getSelectList()
  // 获取推广数据列表
  getList()
})

// 新增
function touchAdd () {
  RefAdd.value.showDrawer()
}

// 编辑
function touchEdit (record) {
  RefAdd.value.showDrawer(record)
}
// 删除
function touchDelete (record) {
  Modal.confirm({
    title: '提示',
    content: '确认要进行该操作吗？',
    cancelText: '取 消',
    okText: '确 定',
    onOk: () => {
      isLoading.value = true
      const params = {
        id: record.id
      }
      wechatAccountDelete(params).then(res => {
        isLoading.value = false
        const { code, data, msg } = res
        if (code === 0) {
          message.success('删除成功')
          getList()
        } else {
          message.error(res.message || msg)
        }
      }).catch(() => {
        message.error('删除失败')
      })
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 初始化选择项列表
function getSelectList () {
}

// 获取推广数据列表
function getList () {
  isLoading.value = true
  const params = {
    page: pagination.current,
    page_size: pagination.pageSize
  }
  wechatAccountList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      const list = data.data
      list.forEach(item => {
        const names = []
        const projectids = []
        const projects = item.project || []
        projects.forEach(p => {
          names.push(p.name)
          projectids.push(p.project)
        })
        item.projectids = projectids
        item.project_name = names.join('、')
      })
      dataSource.value = list
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}
</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}

</style>